import DailyStatisticsCustomData from 'modules/ReportingModule/pages/InnerReportingPage/pages/dashboard/DailyStatisticsCustomData';
import React from 'react';
import { useGetUrlTreeQuery } from 'modules/UrlModule/api/urls.api';
import AutomaticDrawer from 'components/Drawers/AutomaticDrawer';
import useQueryParams from 'hooks/useQueryParams';

const AdDetailDrawer = (props) => {
  const { searchParams, removeParams } = useQueryParams();
  const { adset_details_id } = searchParams;

  const { data, isLoading } = useGetUrlTreeQuery(
    { id: adset_details_id },
    { skip: adset_details_id === null }
  );

  return (
    <AutomaticDrawer size='large' title={data?.adset?.name} {...props}>
      {data?.adset?.id ? (
        <DailyStatisticsCustomData hideTitle innerId={data?.adset?.id} moduleId='reporting' />
      ) : null}
    </AutomaticDrawer>
  );
};

export default AdDetailDrawer;

AdDetailDrawer.requiredParams = {
  adset_details_id: (p) => parseInt(p) > 0,
};
